@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

/* .swiper-button-prev {
  background-image: url('/assets/Farrow_left.svg');
}

.swiper-button-next {
  background-image: url('/assets/Farrow_right.svg');
} */

:root {
  --fontBase: 'Plus Jakarta Sans', Arial, sans-serif;
  --fontPrimary: ABeeZee, Arial, sans-serif;
  --fontSecondary: 'Playfair Display', Arial, sans-serif;
  --fontInfo: Questrial, Arial, sans-serif;
}

.swiper-button-prev,
.swiper-button-next {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transform: scale(1.2);
}

.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
}

.swiper-pagination-bullet-active {
  background-color: #263238 !important;
}

.custom-scroll-bar {
  overflow: auto;
}

.custom-scroll-bar::-webkit-scrollbar {
  overflow: 'auto';
  width: 3px;
}

.custom-scroll-bar::-webkit-scrollbar-thumb {
  background-color: #fcae4c;
  border-radius: 10px;
}

.custom-scroll-bar::-webkit-scrollbar-track:vertical {
  margin-bottom: 20px;
}

input::placeholder {
  color: #728f8b91 !important;
}

textarea::placeholder {
  color: #728f8b91 !important;
}

.glowing-circle {
  /* border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 3px 2px #fff,
    0 0 5px 2px #f5ca09; */
}

textarea {
  resize: none;
}

.fixed-width-container {
  @apply max-w-[1600px] ml-auto mr-auto;
}

.editor tr {
  border-top: 1px solid #c6cbd1;
  /* background: #fff; */
}

.editor th,
.editor td {
  padding: 12px 55px;
  border: 1px solid #dfe2e5;
}

.editor table tr:nth-child(2n) {
  /* background: #f6f8fa; */
}

.editor h1 {
  font-size: 2em;
}

.editor h2 {
  font-size: 1.5em;
}

.editor h3 {
  font-size: 1.17em;
}

.editor h4 {
  font-size: 1em;
}

.editor h5 {
  font-size: 0.83em;
}

.editor h6 {
  font-size: 0.67em;
}

.editor h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bolder;
  margin-bottom: 2rem;
}

.editor p {
  margin-bottom: 2rem;
}

.editor ul {
  padding-left: 25px;
  margin-bottom: 2rem;
}

.editor li {
  padding-left: 10px;
  margin-bottom: 1rem;
}

.editor blockquote {
  margin: 10px;
  padding-left: 1.2em;
  border-left: 0.2em #666 solid;
  font-style: italic;
}

.editor a {
  color: rgb(46, 46, 233);
}

.editor li {
  list-style-type: square;
}

.fullheight {
  height: 92vh;
}

.blog-item {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.blog-item.loaded {
  opacity: 1;
  transform: translateY(0);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.loader {
  animation: fadeIn 1s ease-in-out;
}
